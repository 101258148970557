@use '../shared/global.style.module.scss' as g;

.headerContainer {
  display: flex;
  width: 100vw;
  justify-content: center;
  position: fixed;
  z-index: 9;
  transition: ease-out 0.8s;
  backface-visibility: hidden;
  transform: translateY(0);

  height: var(--spacing-header-small);
  @include g.responsive(g.$MEDIUM) {
    height: var(--spacing-header-big);
  }
}

.contactUsButton {
  display: none !important;
  @include g.responsive(g.$LARGE) {
    display: flex !important;
  }
}

.headerInnerContainer {
  display: flex;
  width: 100%;
  height: var(--spacing-header-small);
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-24) var(--side-margin-small);
  z-index: 3;

  @include g.responsive(g.$MEDIUM) {
    height: var(--spacing-header-big);
    width: var(--container-width-medium);
    padding: var(--spacing-24) 0;
  }
  @include g.responsive(g.$LARGE) {
    width: var(--container-width-large);
    padding: var(--spacing-24) 0;
  }
  @include g.responsive(g.$X_LARGE) {
    width: var(--container-width-xl);
    padding: var(--spacing-24) 0;
  }
}

.headerLogo {
  width: 80px;
  height: 18px;
  cursor: pointer;
  @include g.responsive(g.$MEDIUM) {
    width: 114px;
    height: 25px;
  }
}

.contactUsButton {
  display: none;

  @include g.responsive(g.$MEDIUM) {
    display: none;
  }
  @include g.responsive(g.$LARGE) {
    display: block;
  }
  @include g.responsive(g.$X_LARGE) {
    display: block;
  }
}

.menuButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 56px;
  padding: 8px;

  @include g.responsive(g.$LARGE) {
    display: none;
  }
  @include g.responsive(g.$X_LARGE) {
    display: none;
  }
}

.centerContainer {
  margin: auto;
  display: none;
  align-items: center;

  @include g.responsive(g.$MEDIUM) {
    display: none;
  }
  @include g.responsive(g.$LARGE) {
    display: flex;
    gap: var(--spacing-32);
  }
  @include g.responsive(g.$X_LARGE) {
    gap: var(--spacing-64);
  }
}

.button {
  @include g.responsive(g.$LARGE) {
    height: 56px;
    font-weight: 600;
  }
}

.mobileMenuContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  transition:
    opacity 0.5s,
    transform 0.8s;
  transform: translateY(-100%);
  padding: var(--spacing-header-small) var(--spacing-16);
  background: var(--background);
  overflow-y: scroll;
  opacity: 0;

  &.mobile-menu-open {
    transform: translateY(0px);
    opacity: 1;
  }

  @include g.responsive(g.$MEDIUM) {
    padding: var(--spacing-header-big) calc((100vw - var(--container-width-medium)) / 2);
  }

  @include g.responsive(g.$LARGE) {
    &.mobile-menu-open {
      display: none;
    }
  }

  &.dark {
    background: var(--background-dark);
  }
}

.mobileMenuButton {
  padding: 16px 0 !important;
  font-weight: 600;
}

.mobileMenuSectionLabel {
  padding: 16px 0;
  font-weight: 600;
}

.mobileDivider {
  width: 100%;
  min-height: 1px;
  height: 1px;
  background-color: #1a0e2f;

  &.dark {
    background-color: #ffffff;
  }
}

.mobileMenuTitle {
  padding: 8px 0;
  background: linear-gradient(265deg, #9747ff -16.79%, #7a98ff 118.81%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mobileMenuSubButton {
  padding: 8px 0;
  color: var(--text-button-default);

  &.dark {
    color: var(--white-primary);
  }
}

.desktopMenuContainer {
  height: auto;
  width: auto;
  display: none;
  justify-content: space-between;
  position: fixed;
  padding: 24px;
  border-radius: 16px;
  transition: 0.5s;
  gap: 48px;
  transform: translateY(50px);
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  background: #fbfafc;
  box-shadow: 0 0 16px 0 rgba(32, 9, 71, 0.2);

  &.desktopMenuOpen {
    transform: translateY(var(--spacing-header-big));
    z-index: 10;
    opacity: 1;
    pointer-events: all;
  }
  @include g.responsive(g.$MEDIUM) {
    display: flex;
  }

  &.dark {
    background: rgba(251, 250, 252, 0.2);
    backdrop-filter: blur(80px);
  }
}

.desktopMenuColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.desktopMenuTitle {
  @include g.h6;
  padding: 0 var(--spacing-8);
  color: var(--themed-text-color);
}

.desktopMenuItem {
  @include g.bodyRegular;
  font-size: var(--font-size-body-regular) !important;
  font-weight: var(--font-weight-body-regular) !important;
  line-height: var(--line-height-body-regular) !important;
}

.closeButton {
  height: 30px !important;
  width: 30px !important;
  transition: 0.4s;
  cursor: pointer;
  display: inline-flex !important;
  flex-direction: column;
  justify-content: space-around;
  gap: 0 !important;
  padding: 0 !important;

  @include g.responsive(g.$LARGE) {
    display: none !important;
  }

  &:hover {
    background: none;
  }
}

.animatedBar {
  height: 3px;
  width: 26px;
  display: block;
  position: relative;
  border-radius: 10px;
  transition: 0.4s;
  background-color: var(--background-dark) !important;

  &.dark {
    background-color: var(--background) !important;
  }
}

.animatedBarActive {
  &:nth-of-type(1) {
    transform: translateY(10px) rotate(45deg);
  }
  &:nth-of-type(2) {
    opacity: 0;
  }
  &:nth-of-type(3) {
    transform: translateY(-10px) rotate(-45deg);
  }
}

@keyframes rotateR {
  from {
    transform: translateY(0px) rotate(0);
  }
  to {
    transform: translateY(15px) rotate(45deg);
  }
}

@keyframes rotateL {
  from {
    transform: translateY(0px) rotate(0);
  }
  to {
    transform: translateY(-15px) rotate(-45deg);
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
